import aquariumImg from "../assets/aquarium.png";
import seaTripImg from "../assets/sea-trip.png";
import mediaImg from "../assets/media.png";

export default [
  {
    route: "sea-trip",
    background: seaTripImg,
    name: "sea trip",
  },
  {
    route: "grand-aquarium",
    background: aquariumImg,
    name: "grand aquarium",
  },
  {
    route: "media",
    background: mediaImg,
    name: "media",
  },
  {
    route: "dolphin-house",
    background: mediaImg,
    name: "dolphin house",
  },
];
